.sm-activities-wrapper {
  color: #172b4d;
  width: 100%;
  height: 100%;
  background: #fff;
  border: none;
  min-height: 100px;

  .close {
    position: absolute;
    right: 18px;
    top: 24px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 24px;
    font-weight: lighter;
    color: #1f2937;
    cursor: pointer;
    background: #fff;
    z-index: 9;
    text-align: center;

    &:hover {
      color: #222;
    }
  }

  hr {
    margin: 0;
  }

  h2 {
    font-size: 24px;
    color: #000000;
    font-weight: bold;
  }

  .activities-section {
    overflow-y: overlay;
    height: calc(100% - 100px);

    .tail-location {
      height: calc(100% - 28px);
    }

    .sm-group-status-icon {
      svg {
        vertical-align: middle !important;

        path {
          fill: var(--secondary-text-500) !important;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 16px;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #6b72804d;
    }

    .ant-steps-vertical {
      .sm-step {
        .step-dot {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 28px;
          left: -4px;
          position: relative;

          .dot {
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background-color: var(--gray-400);
          }
        }

        &.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
          background-color: var(--gray-200);
        }

        .ant-steps-item-content {
          margin-bottom: 12px;
          padding-right: 5px;
        }

        .ant-steps-item-tail {
          padding: 28px 0 0;
          left: 14px;
        }

        .ant-steps-item-icon {
          width: unset;
        }

        .ant-steps-icon {
          @apply sm_body_b2_semi;

          svg {
            vertical-align: top;
          }
        }

        &.sm-big-step {
          .ant-steps-item-title {
            color: var(--gray-800);
            @apply sm_body_b2_semi;
          }
        }

        .ant-steps-item-title {
          @apply sm_body_b2_semi;
          color: var(--gray-600);
          padding-right: unset;
        }

        &.un-highlight-title {
          .ant-steps-item-title {
            font-weight: 400;
          }
        }

        .ant-steps-item-description {
          @apply sm_body_b2_reg;
          color: var(--gray-600);
        }

        &.ant-steps-item-process {
          &:not(.sm-big-step) {
            .step-dot {
              height: 16px;
            }

            .ant-steps-item-tail {
              padding: 20px 0 0;
            }
          }

          &.primary {
            .ant-steps-item-tail::after {
              background-color: var(--blue-400);
            }

            .ant-steps-item-title {
              color: var(--blue-500);
            }

            .step-dot .dot {
              background-color: var(--blue-400);
            }
          }

          &.danger {
            .ant-steps-item-tail::after {
              background-color: var(--red-500);
            }

            .ant-steps-item-title {
              color: var(--red-500);
            }

            .step-dot .dot {
              background-color: var(--red-500);
            }
          }
        }

        &.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
        .ant-steps-item:last-child::after {
          display: block;
        }

        &.un-tail {
          &.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
          .ant-steps-item:last-child::after {
            display: none !important;
            height: 0;
          }
        }
      }
    }
  }
}

.sm-tooltip-event-details {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      padding: 0;
      border-radius: 4px;

      .event-detail-content {
        .label {
          @apply sm_body_b3_reg;
          color: var(--gray-300);
        }

        .value {
          @apply sm_body_b2_semi;
          color: #ffffff;
        }

        &::-webkit-scrollbar {
          width: 8px;
          border-radius: 8px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
          border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background: var(--gray-400);
          border-radius: 8px;
        }
      }
    }
  }
}
